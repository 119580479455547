import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import { ComponentMap } from '../utils/componentMap'
import Header from '../components/header'

class ContentPageTemplate extends React.Component {
  render() {
    const pageData = get(this.props, 'data.contentfulContentPage')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div className={'pageContent'}>
          <Helmet title={`${pageData.title} - ${siteTitle || 'Zonta Club of Toronto'}`} />
          <Header
            backgroundVideo={pageData.heroVideo}
            backgroundImage={pageData.heroImage}
            title={pageData.title}
            description={pageData.description}
          />
          <div className="wrapper">
            {pageData.body && pageData.body.childMarkdownRemark && (<div
              className="body-markup"
              dangerouslySetInnerHTML={{
                __html: pageData.body.childMarkdownRemark.html,
              }}
            />)}
            {pageData.pageContent && pageData.pageContent.map(content => {
              const Component = ComponentMap.find(component => content.__typename === component.type).component;
              return <Component content={content} />
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContentPageTemplate

export const pageQuery = graphql`
query ContentPageBySlug($slug: String!) {
  contentfulContentPage(slug: {eq: $slug}) {
    title
    heroImage {
      description
      fluid {
        src
        srcSetWebp
        srcSet
      }
      file {
        contentType
      }
    }
    heroVideo {
      file {
        url
        contentType
      }
      description
      title
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    body {
      childMarkdownRemark {
        html
      }
    }
    pageContent {
      ... on ContentfulScholarshipAward {
        body {
          childMarkdownRemark {
            html
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        heroImage {
          description
          fluid {
            src
            srcSetWebp
            srcSet
          }
          file {
            contentType
          }
        }
        title
        slug
        zontaInternational
      }
      ... on ContentfulProject {
        description {
          childMarkdownRemark {
            html
          }
        }
        body {
          childMarkdownRemark {
            html
          }
        }
        address
        websiteUrl
        title
        slug
        phoneNumber
        heroImage {
          description
          fluid {
            src
            srcSetWebp
            srcSet
          }
          file {
            contentType
          }
        }
        event {
          title
          slug
          locationName {
            childMarkdownRemark {
              html
            }
          }
          dateTime
          cost
          url
        }
      }
      ... on ContentfulPerson {
        heroImage {
          description
          fluid {
            src
            srcSetWebp
            srcSet
          }
          file {
            contentType
          }
        }
        heroVideo {
          file {
            url
            contentType
          }
          description
          title
        }
        shortBio {
          childMarkdownRemark {
            html
          }
        }
        email
        company
        facebook
        linkedIn
        name
        phone
        twitter
        title
      }
      ... on ContentfulEvent {
        heroImage {
          description
          fluid {
            src
            srcSetWebp
            srcSet
          }
          file {
            contentType
          }
        }
        heroVideo {
          file {
            url
            contentType
          }
          description
          title
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        title
        slug
        locationName {
          childMarkdownRemark {
            html
          }
        }
        location {
          lat
          lon
        }
        dateTime
        cost
        url
      }
    }
  }
}

`

import React from 'react'

import styles from './project.module.css'
import InlineEvent from './inline-event'

export default ({ content }) => (
  <div className={styles.wrapper}>
    {content.heroImage && (
      <div className={styles.imageBlock}>
        <img className={styles.image} alt={content.heroImage.description} srcset={content.heroImage.fluid.srcSetWebp} />
      </div>
    )}
    <div className={styles.infoBlock}>
      <h3 className={styles.previewTitle}>
        {content.title}
      </h3>
      <p
        dangerouslySetInnerHTML={{
          __html: content.description.childMarkdownRemark.html,
        }}
      />
      <a href={`/service-projects/${content.slug}`} className={'cta button primary'}><span className={'innerText'}>Learn More</span></a>
      {content.event && <InlineEvent content={content.event} />}
    </div>
  </div>
)

import React from 'react'

import styles from './inline-event.module.css'

export default ({ content }) => (
  <div className={styles.wrapper}>
    <div className={styles.infoBlock}>
      <h3 className={styles.previewTitle}>
        {content.title}
      </h3>
      {content.cost >= 0 && (
        <p>
          <strong>Entry Fee: </strong>
          <span>{content.cost && content.cost <= 0 ? 'Free' : `$${content.cost}`}</span>
        </p>
      )}
      <p
        dangerouslySetInnerHTML={{
          __html: content.locationName.childMarkdownRemark.html,
        }}
      />
      {content.url && <><strong>Register at </strong><a href={content.url}>{content.url}</a></>}
    </div>
  </div>
)

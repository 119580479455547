import React from 'react'

import styles from './event.module.css'
import { renderDate } from '../utils/renders'

export default ({ content }) => (
  <div className={styles.wrapper}>
    {content.heroImage && (
      <div className={styles.imageBlock}>
        <img className={styles.image} alt={content.heroImage.description} srcset={content.heroImage.fluid.srcSetWebp} />
      </div>
    )}
    <div className={styles.infoBlock}>
      <h3 className={styles.previewTitle}>
        {content.title}
      </h3>
      {/* content.description && (
        <p
          dangerouslySetInnerHTML={{
            __html: content.description.childMarkdownRemark.html,
          }}
        />
        ) */}
      {content.dateTime && <p>{renderDate(content.dateTime)}</p>}
      {content.cost >= 0 && (
        <p>
          <strong>Entry Fee: </strong>
          <span>{content.cost && content.cost <= 0 ? 'Free' : `$${content.cost}`}</span>
        </p>
      )}
      {content.locationName && (
        <p>
          <strong>Location: </strong>
          <div
            dangerouslySetInnerHTML={{
              __html: content.locationName.childMarkdownRemark.html,
            }}
          />
        </p>
      )}
      {content.url && <a href={content.url} className={`${styles.eventCta} cta button primary`} target={'_blank'} rel="noreferrer"><span className={'innerText'}>Register</span></a>}
      <a href={`/events/${content.slug}`} className={`${styles.eventCta} cta button secondary`}><span className={'innerText'}>Event Details</span></a>
    </div>
  </div>
)

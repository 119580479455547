import React from 'react'

export const renderDate = function renderDate(dateTimeStr) {
  const dateTime = new Date(dateTimeStr);
  const currentDate = new Date();

  const dateStr = dateTime.getFullYear() !== currentDate.getFullYear()
    ? dateTime.toLocaleString('en-CA', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })
    : dateTime.toLocaleString('en-CA', { weekday: 'long', month: 'long', day: 'numeric' });

  const timeStr = dateTime.toLocaleString('en-CA', { timeStyle: 'short' });

  return <><strong>{dateTime < currentDate ? 'Was held on ' : 'Date: '}</strong><span>{`${dateStr} at ${timeStr}`}</span></>;
}
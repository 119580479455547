import React from 'react'

import styles from './zonta-member.module.css'

export default ({ content }) => (
  <div className={styles.wrapper}>
    {content.heroImage && (
      <div className={styles.imageBlock}>
        <img className={styles.image} alt={content.heroImage.description} srcset={content.heroImage.fluid.srcSetWebp} />
      </div>
    )}
    <div className={styles.infoBlock}>
      {content.name && (
        <h3 className={styles.previewTitle}>
          {content.name}
        </h3>
      )}
      {content.title && (
        <p className={styles.previewTitle}>
          {content.title}
        </p>
      )}
      {content.company && (
        <p className={styles.previewTitle}>
          {content.company}
        </p>
      )}
      {content.email && (
        <p className={styles.previewTitle}>
          {content.email}
        </p>
      )}
      {content.phone && (
        <p className={styles.previewTitle}>
          {content.phone}
        </p>
      )}
      {content.linkedin && (
        <p className={styles.previewTitle}>
          {content.linkedin}
        </p>
      )}
      {content.twitter && (
        <p className={styles.previewTitle}>
          {content.twitter}
        </p>
      )}
      {content.facebook && (
        <p className={styles.previewTitle}>
          {content.facebook}
        </p>
      )}
      {content.email && (
        <p className={styles.previewTitle}>
          {content.email}
        </p>
      )}
      {content.email && (
        <p className={styles.previewTitle}>
          {content.email}
        </p>
      )}
      {content.shortBio && (
        <p
          dangerouslySetInnerHTML={{
            __html: content.shortBio.childMarkdownRemark.html,
          }}
        />
      )}
    </div>
  </div>
)

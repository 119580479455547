import React from 'react'

import styles from './content-page.module.css'

export default ({ content }) => (
  <div className={styles.wrapper}>
    <div className={styles.imageBlock}>
      <img className={styles.image} alt={content.heroImage.description} srcset={content.heroImage.fluid.srcSetWebp} />
    </div>
    <div className={styles.infoBlock}>
      <h3 className={styles.previewTitle}>
        {content.title}
      </h3>
      <p
        dangerouslySetInnerHTML={{
          __html: content.description.childMarkdownRemark.html,
        }}
      />
      <a href={`${content.parentPage && content.parentPage.slug ? `/${content.parentPage.slug}` : ''}/${content.slug}`} className={'cta button primary'}><span className={'innerText'}>Learn More</span></a>
    </div>
  </div>
)

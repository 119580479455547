import ScholarshipAward from '../components/scholarship-award'
import Project from '../components/project'
import SocialEvent from '../components/event'
import ZontaMember from '../components/zonta-member'
import ContentPage from '../components/content-page'

export const ComponentMap = [
  { component: SocialEvent, type: "ContentfulEvent" },
  { component: ScholarshipAward, type: "ContentfulScholarshipAward" },
  { component: Project, type: "ContentfulProject" },
  { component: ContentPage, type: "ContentfulContentPage" },
  { component: ZontaMember, type: "ContentfulPerson" },
];